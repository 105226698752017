<template>
    <div class="container rtl">

        <div class="row py-3">
            <div class="col">
                <form @submit.prevent="addstore" autocomplete="off">
                    <div class="row py-3">
                        <div class="col"><span>زیاکردنی مەخزەن</span></div>
                        <div class="btn-group" data-toggle="buttons">
                            <label class="btn btn-primary active">
                                نوێکردنەوە
                                <input type="checkbox" v-model="resetable">
                            </label>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <label>ناوی مەخزەن</label>
                            <input v-model="store.store_name" class="form-control" placeholder="ناوی مەخزەن" type="text"
                                required>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label> شار </label>
                            <select v-model="store.city_id" class="form-control" required>
                                <option :value="item.city_id" v-for="(item, index) in cities" :key="index">
                                    {{item.city_name_ku}}</option>
                            </select>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary m-2">زیادکردن</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row my-3 border-top">
            <hr>
        </div>
        <div class="row">
            <div class="col">
                <table class="table table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">ناوی مەخزەن</th>
                            <th scope="col">شار</th>
                            <th scope="col"> ئەکتیڤیتی مەخزەن</th>
                            <th scope="col">گۆڕانکاری</th>
                            <th scope="col">سڕینەوە</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in allstores" :key="index"
                            :class="item.store_status == 'active' ? '' : 'text-danger'">
                            <td>{{item.store_name}}</td>
                            <td>{{item.city_name_ku}}</td>
                            <td>{{item.store_status}}</td>
                            <td>
                                <button @click="openstore(item , 'remove')" type="button" class="btn btn-danger btn-sm">
                                    سڕینەوە
                                </button>
                            </td>
                            <td>
                                <button @click="openstore(item , 'update')" type="button"
                                    class="btn btn-success btn-sm">
                                    گۆڕانکاری
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <!-- Delete Modal -->
        <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">سڕینەوەی مەخزەن</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        دڵنیایت لە سڕینەوەی مەخزەن؟
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                        <button type="button" class="btn btn-danger" @click="deletestore">سڕینەوە</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Update Modal -->
        <div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form @submit.prevent="updatestore">
                        <div class="modal-header">
                            <h5 class="modal-title">نوێکردنەوەی مەخزەن</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6 my-1">
                                    <label>ناوی مەخزەن</label>
                                    <input v-model="selected_store.store_name" class="form-control form-control-lg"
                                        placeholder="ناوی مەخزەن" type="text" required>
                                </div>
                                <div class="col-6 my-1">
                                    <label> ئەکتیڤیتی </label>
                                    <select v-model="selected_store.store_status" class="form-control" required>
                                        <option>active</option>
                                        <option>deactive</option>
                                    </select>
                                </div>
                                <div class="col-6 my-1">
                                    <label> شار </label>
                                    <select v-model="selected_store.city_id" class="form-control" required>
                                        <option :value="item.city_id" v-for="(item, index) in cities" :key="index">
                                            {{item.city_name_ku}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                            <button type="submit" class="btn btn-success">نوێکردنەوە</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                selected_store: {},
                store: {},
                resetable: false,
                msg: {
                    color: '',
                    model: false,
                    text: ''
                }
            }
        },
        computed: {
            allstores() {
                return this.$store.getters.store
            },
            cities() {
                return this.$store.getters.cities
            },

        },
        methods: {
            getstores() {
                $('#dataTable').DataTable().destroy();
                axios.get('store/read.php')
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.$store.state.store = r.data.data
                            this.$nextTick(() => {
                                $('#dataTable').DataTable();
                            })
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            addstore() {
                let params = this.store
                axios.post('store/create.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            if (this.resetable) {
                                this.store = {};
                            }
                            this.getstores()
                            this.msg = {
                                model: true,
                                text: 'مەخزەن زیادکرا',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        } else {
                            alert(r.data.message);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            deletestore() {
                let params = {
                    store_id: this.selected_store.store_id
                }
                axios.post('store/delete.php', params)
                    .then(r => {
                        console.log(r);
                        if (r.data.status == 'ok') {
                            $('#deleteModal').modal('hide')
                            this.getstores()
                            this.msg = {
                                model: true,
                                text: ' مەخزەن سڕایەوە',
                                color: 'danger'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            updatestore() {
                let params = this.selected_store
                axios.post('store/update.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.getstores()
                            $('#updateModal').modal('hide')
                            this.msg = {
                                model: true,
                                text: 'مەخزەن نوێکرایەوە',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        } else {
                            alert(r.data);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            openstore(item, type) {
                this.selected_store = item
                if (type == 'remove') {
                    $('#deleteModal').modal('show')
                } else {
                    $('#updateModal').modal('show')
                }
            }
        },
        mounted() {
            $('#deleteModal').modal('hide')
            $('#updateModal').modal('hide')
            $('#dataTable').DataTable();
            console.log(this.cities);
            this.getstores()
        },
    }
</script>